import React from 'react';
import FormRow from '../../../../components/Form';
import { settingsRequiredWhenEnabled } from '../../../../utils';
import { gql } from '@apollo/client';
import logo from '../../../../assets/images/plugins/roularta.png';

export const id = 'roularta';
export const name = 'Roularta';
export const description = 'Custom roularta plugin';
export const image = logo;

export const genSchema = yup =>
  yup.object().shape({
    cookieName: settingsRequiredWhenEnabled(yup.string().label('Cookie name')),
    field: settingsRequiredWhenEnabled(yup.string().label('Field')),
  });

/**
 * @type {import('../../../../../types/settings').FormSection<{ id: string, url: string }>}
 */
export const SettingsForm = ({
  value = {},
  inheritedValue = {},
  errors = {},
  isEnabled,
  showInheritanceBadge,
}) => {
  return (
    <>
      <FormRow
        name="cookieName"
        label="Cookie name"
        value={value.cookieName}
        inheritedValue={inheritedValue.cookieName.inheritedValue}
        showInheritanceBadge={showInheritanceBadge}
        disabled={!isEnabled}
        error={errors['cookieName']}
      />
      <FormRow
        name="field"
        label="Field"
        value={value.field}
        inheritedValue={inheritedValue.field.inheritedValue}
        showInheritanceBadge={showInheritanceBadge}
        error={errors['field']}
      />
    </>
  );
};

export const FRAGMENT_PLUGIN_SETTINGS = {
  name: 'RoulartaPluginSettings',
  fragment: gql`
    fragment RoulartaPluginSettings on RoulartaPlugin {
      cookieName {
        value
        inheritedValue
      }
      field {
        value
        inheritedValue
      }
    }
  `,
};
